<template>
  <div>
    <search-field title="条件筛选">
      <el-form :inline="true" size="small" label-width="100px">
        <el-form-item label="申请时间">
          <div class="block">
            <el-date-picker
              clearable
              v-model="search.searchFields.create_time_start"
              placeholder="请选择"
              type="datetime"
              value-format="timestamp"
              style="margin-right:10px"
            ></el-date-picker>
            至
            <el-date-picker
              clearable
              placeholder="请选择"
              v-model="search.searchFields.create_time_end"
              type="datetime"
              value-format="timestamp"
              style="margin-left:10px"
            ></el-date-picker>
          </div>
        </el-form-item>
        <el-form-item label="处理进度">
          <el-select clearable v-model="search.searchFields.operation_status" placeholder="请选择">
            <el-option
              v-for="item in operation_status_list"
              :key="item.code"
              :label="item.value"
              :value="item.code"
            ></el-option>
          </el-select>
        </el-form-item>
      </el-form>
      <div class="search-button-container">
        <el-button type="primary" size="small" @click="getIntegralMoneyList(1)">查询</el-button>
        <el-button size="small"  @click="clearSearch()" plain>重置</el-button>
      </div>
    </search-field>
    <div class="page-content">
      <div class="button-field">
        <el-button type="primary" size="small" @click="handleBatch(3)">批量拒绝</el-button>
        <el-button type="primary" size="small" @click="handleBatch(2)">批量成功</el-button>
      </div>
      <v-table
        :selection="true"
        :data="options.data"
        :columns="options.columns"
        :isBackPage="true"
        :totalCount="options.totalCount"
        :defaultcurrentPage="search.searchFields.page"
        @handleCurrentChange="handlePageChange"
        @handleSizeChange="handleSizeChange"
      >
      </v-table>
    </div>
  </div>
  </template>
  <script>
  
  import vTable from '@/components/tools/vtable'
  import searchField from '@/components/tools/searchField'
  import { BAApi } from '@/api'
  import { formatDate } from '@/libs/utils'
  
  export default {
    data() {
      return {
        options: {
          columns: [
            {
              label: 'ba姓名',
              key: 'name'
            },
            {
              label: '积分消耗',
              key: 'integral_num'
            },
            {
              label: '现金金额',
              key: 'money'
            },
            {
              label: '申请日期',
              key: 'create_time'
            },
            {
              label: '处理状态',
              key: 'operation_status_msg'
            }
          ],
          data: [],
          totalCount: 0,
          loading: false,
        },
        search: {
          searchFields: {
            page: 1,
            count: 20,
            create_time_start: '',
            create_time_end: '',
            operation_status: 1,
          }
        },
        operation_status_list: [
          {
            code: 0,
            value: '全部'
          },
          {
            code: 1,
            value: '待处理'
          },
          {
            code: 2,
            value: '处理成功'
          },
          {
            code: 3,
            value: '拒绝发放'
          }
        ],
        updateParams: {}
      }
    },
  
    methods: {
      // 后端分页
      handlePageChange(page) {
        this.search.searchFields.page = page
        this.getIntegralMoneyList()
      },
      handleSizeChange(count) {
        this.search.searchFields.count = count
        this.getIntegralMoneyList(1)
      },
      async getIntegralMoneyList(page) {
        this.search.searchFields.page = page || this.search.searchFields.page
        let params = {...this.search.searchFields}
        if(this.search.searchFields.create_time_start) {
          params.create_time_start = formatDate(Number(this.search.searchFields.create_time_start), 'YYYY-MM-DD HH:mm:ss').dateString
        }
        if(this.search.searchFields.create_time_end) {
          params.create_time_end = formatDate(Number(this.search.searchFields.create_time_end), 'YYYY-MM-DD HH:mm:ss').dateString
        }
        try {
          let data = (await BAApi.getIntegralMoneyList(params)).data
          if (data.code === 0) {
            this.options.data = data.receipt_list
            this.options.totalCount = parseInt(data.total_count) || 0
          } else {
            this.$error(data)
          }
        } catch (err) {
          console.error(err)
        }
      },
      clearSearch() {
        this.search = {
          searchFields: Object.assign({ count: this.search.searchFields.count }, {
            page: 1,
            create_time_start: '',
            create_time_end: '',
            operation_status: '',
          })
        }
      },
      handleBatch(type) {
        let data = this.$store.state.Table.rowAction
        if (data.length > 0) {
          let ids = data.map(item => item.id)
          this.updateParams.money_ids = ids.join()
          this.updateParams.operation_status = type
          let tip = `确定全部${type === 2 ? '成功' : '拒绝'}？`
          return this.$confirm(tip, '提示', {
              confirmButtonText: '确定',
              cancelButtonText: '取消',
              type: 'warning'
            }).then(() => {
              this.changeStatus()
            })
        } else {
          this.$message.error('请先进行选择')
          return
        }
      },
      async changeStatus() {
        let loading = this.$loading()
        try {
          let data = (await BAApi.integralMoneyUpdate(this.updateParams)).data
          if (data.code === 0) {
            this.$message.success('审核成功')
            this.getIntegralMoneyList()
          } else {
            this.$error(data)
          }
        } catch (err) {
          console.error(err)
        } finally {
          loading.close()
        }
      },
    },
  
    mounted() {
      this.getIntegralMoneyList()
    },
    components: {
      vTable, searchField
    }
  }
  </script>
  
  <style lang="less">
  .status-td {
    display: flex;
    align-items: center;
    justify-content: center;
    .tag {
      width: 12px;
      height: 12px;
      display: inline-block;
      border-radius: 50%;
      margin-right: 6px;
    }
    .tag1 {
      background-color: #409EFF;
    }
    .tag2 {
      background-color: #67C23A;
    }
    .tag3 {
      background-color: #E6A23C;
    }
    .tag4 {
      background-color: #F56C6C;
    }
  }
  </style>
  